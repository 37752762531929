<template>
    <div
        ref="chart"
        :style="`height: ${height}px`"
    />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { hslToHex } from '../utils/form-utils.js';

export default {
    props: {
        chartData: {
            default: () => null,
            type: Array,
            required: true,
        },

        unknownTag: {
            default: () => 'Unknown',
            type: String,
            required: false
        }
    },

    data() {
        return {
            height: 450,
            data: this.chartData,
            chart: null
        };
    },

    mounted() {
        this.preparePieChartData(this.data);
        this.chart = this.pieChart();
    },

    beforeUnmount() {
        if (this.chart)
            this.chart.dispose();
    },

    methods: {
        preparePieChartData(data) {
            let sum = 0;
            data.forEach(elem => {
                sum += elem.value;
            });

            if (sum < 100) {
                data.push({
                    title: this.unknownTag,
                    value: 100 - sum
                });
            }
        },

        pieChart() {
            /* Chart code */
            // Themes begin
            am4core.useTheme(am4themes_animated);
            // Themes end

            // create chart
            let chart = am4core.create(this.$refs.chart, am4charts.PieChart);

            chart.hiddenState.properties.opacity = 0;

            chart.radius = am4core.percent(50);
            chart.innerRadius = am4core.percent(30);
            chart.startAngle = 180;
            chart.endAngle = 360;  

            let series = chart.series.push(new am4charts.PieSeries());
            series.dataFields.value = "value";
            series.dataFields.category = "title";

            series.slices.template.cornerRadius = 10;
            series.slices.template.innerCornerRadius = 7;
            series.slices.template.draggable = false;
            series.slices.template.inert = true;
            series.alignLabels = false;
            series.labels.template.maxWidth = 90;
            series.labels.template.wrap = true;


            chart.events.on("transformed", hideSmall)

            function hideSmall(ev) {
                if (ev.target.pixelWidth <= 530) {
                    series.ticks.template.visible = false;
                    series.labels.template.visible = false;
                } else {
                    series.ticks.template.visible = true;
                    series.labels.template.visible = true;
                }
            }

            series.hiddenState.properties.startAngle = 90;
            series.hiddenState.properties.endAngle = 90;

            chart.legend = new am4charts.Legend();
            chart.data = this.data;
            return chart;
        }
    },
};
</script>
