<template>
    <div
        ref="chart"
        :style="`height: ${height}px`"
    />
</template>

<script>
// import { hslToHex } from '../utils/form-utils.js';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { hslToHex } from '../utils/form-utils.js';

export default {
    props: {
        chartData: {
            default: () => {},
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            height: 700,
            data: this.chartData,
            chart: null
        };
    },

    watch: {
        chartData(newVal) {
            if (Object.keys(newVal).length == 0)
                return;

            if (this.chart != null)
                this.chart.dispose();

            this.data = this.computeColors(newVal);
            this.chart = this.treeMap();
        },
    },

    beforeUnmount() {
        if (this.chart)
            this.chart.dispose();
    },

    methods: {
        treeMap() {
            /* Chart code */
            // Themes begin
            am4core.useTheme(am4themes_animated);
            // Themes end

            // create chart
            let chart = am4core.create(this.$refs.chart, am4charts.TreeMap);
            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

            chart.maxLevels = 1;

            // define data fields
            chart.dataFields.value = "value";
            chart.dataFields.name = "name";
            chart.dataFields.children = "children";
            chart.dataFields.color = "color";


            // level 0 series template
            let level0SeriesTemplate = chart.seriesTemplates.create("0");
            let column0Template = level0SeriesTemplate.columns.template;
            column0Template.fillOpacity = 1;
            column0Template.strokeOpacity = 1;
            column0Template.strokeWidth = 5;
            column0Template.column.cornerRadius(5, 5, 5, 5);
            column0Template.tooltipText = '{childrenName}: {childrenValue}'
            column0Template.adapter.add('tooltipText', function(tooltip, target) {
                const children = target.dataItem.treeMapDataItem.children;
                let text = '';
                for (let i = 0; i < children.length; i++) {
                    const item = children.getIndex(i);
                    let _t = tooltip.replace('{childrenName}', item.properties.name);
                    _t = _t.replace('{childrenValue}', item.value);
                    _t += '\n';
                    text += _t;
                }

                return text;
            });

            let column0label = level0SeriesTemplate.bullets.push(new am4charts.LabelBullet());
            column0label.locationY = 0.5;
            column0label.locationX = 0.5;
            column0label.label.text = '{name}: {value}';
            column0label.label.fill = am4core.color('#000');
            column0label.label.padding(3, 5, 5, 3);
            column0label.label.background = new am4core.RoundedRectangle();
            column0label.label.background.fill = am4core.color('#FFF');
            column0label.label.background.cornerRadius(2, 2, 2, 2);

            let hoverState = column0Template.states.create("hover");
            column0Template.adapter.add("fill", function() {
                return am4core.color('#FFFFFF')
            });

            // darken
            hoverState.adapter.add("fill", function(fill) {
                if (fill instanceof am4core.Color) {
                    return am4core.color(
                        am4core.colors.brighten(fill.rgb, -0.2)
                    );
                }
                return fill;
            });

            let level1SeriesTemplate = chart.seriesTemplates.create("1");
            let column1Template = level1SeriesTemplate.columns.template;
            column1Template.showTooltipOn = false;
            column1Template.column.cornerRadius(5, 5, 5, 5);
            column1Template.fillOpacity = 0.8;
            column1Template.stroke = am4core.color('#FFFFFF');
            column1Template.strokeWidth = 5;
            column1Template.strokeOpacity = 1;

            let column1label = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
            column1label.locationY = 0.5;
            column1label.locationX = 0.5;
            column1label.label.text = '{name}: {value}';
            column1label.label.fill = am4core.color('#000');
            column1label.label.padding(3, 5, 5, 3);
            column1label.label.background = new am4core.RoundedRectangle();
            column1label.label.background.fill = am4core.color('#FFF');
            column1label.label.background.cornerRadius(2, 2, 2, 2);

            // enable navigation
            chart.navigationBar = new am4charts.NavigationBar();
            chart.navigationBar.activeLink.text = '{name}: {value}'

            chart.data = this.data;
            return chart;
        },

        computeColors(data) {
            const map = data.map(c => c.value);
            const max = Math.max.apply(null, map);
            const min = Math.min.apply(null, map);

            const obj = data.map(function(c) {
                const value = c.value;
                const norm =  (value - min) / (max - min);

                const h = 183;
                const s = norm * 90 + 5;
                const l = 50;
                
                const map = c.children.map(sp => sp.value);
                const mmax = Math.max.apply(null, map);
                const mmin = Math.min.apply(null, map);
                c.children = c.children.map(function(sp) {
                    const value = sp.value;
                    const norm = mmax == mmin ? 1 : (value - mmin) / (mmax - mmin);
                    const l = norm * 15 + 35 ;
                    sp.color = hslToHex(h, s, l);
                    return sp;
                });
                c.color = hslToHex(h, s, l);

                return c;
            });
            
            return obj;
        }
    },
};
</script>
