<template>
    <div class="form-row">
        <div class="form-group col-md">
            <label for="year">{{ __('COMMON.YEAR') }}</label>
            <select
                id="year"
                v-model.number="localValue"
                :class="`form-control ${error != '' ? 'is-invalid' : ''}`"
            >
                <option
                    v-for="year in range"
                    :key="year"
                    :value="year"
                >
                    {{ year }}
                </option>
            </select>
            <div
                v-if="error != ''"
                class="invalid-feedback"
            >
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
import Constants from '../Constants.js';
import __ from '../utils/translator.js';

export default {
    name: 'YearSelector',

    props: {
        modelValue: {
            type: Number,
            default: () => 0
        }
    },

    emits: ['update:modelValue', 'year-updated'],

    data() {
        return {
            error: '',

            updateFunc: this.updateMethod,

            range: Array.from( Array(
                    Constants.MAX_KPIDATE_VALUE - (Constants.MIN_KPIDATE_VALUE-1)).keys()
                ).map(e => e + Constants.MIN_KPIDATE_VALUE),

            localValue: this.modelValue
        }
    },

    watch: {
        localValue(newVal) {
            this.$emit('update:modelValue', newVal);
            this.$emit('year-updated');
        },

        modelValue(newVal) {
            this.localValue = newVal;
        }   
    },

    methods: {
        __ //translator
    }
}
</script>