<template>
    <div class="filled-forms">
        <div
            v-for="type in kpiTypes"
            :key="type.id"
            class="filled-forms-body"
        >
            <span
                v-if="filledTypes.indexOf(type.id) == -1"
                class="filled-status bad"
            >
                <i class="fas fa-times-circle" />
            </span>
            <span
                v-else
                class="filled-status ok"
            >
                <i class="fas fa-check-circle" />
            </span>
            <a
                class="kpi-text"
                href=""
                @click="(ev) => goto(ev, `/data-entry/${type.slug}`)"
            >
                {{ type.name }}
            </a>
        </div>
    </div>
</template>

<script>
import { getKpiTypes } from '../db/db-utils.js';
import __ from '../utils/translator.js';

export default {
    props: {
        filledTypes: {
            type: Array,
            required: true
        },
        formsToShow: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            kpiTypes: getKpiTypes(...this.formsToShow)
        }
    },

    methods: {
        goto(ev, path) { //Programamtic navigation
            ev.preventDefault();
            this.$router.push({ path });
        },

        __
    }
}
</script>

<style lang="scss" scoped>
@import '../_custom.scss';

.filled-forms {
    @extend .mb-4, .card, .py-3, .px-4;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    flex-direction: row;

    > div {
        &:not(:last-child) {
            @extend .mr-4;
        }
    }
}

.filled-form-body {
    @extend .card-body, .d-inline;
}

.filled-status {
    @extend .mr-2;
    font-size: 2em;

    &.ok {
        color: map-get($theme-colors, "success");;
    }

    &.bad {
        color: map-get($theme-colors, "danger");;
    }
}

.kpi-text {
    @extend .h5, .text-dark;
    vertical-align: text-bottom;
}
</style>