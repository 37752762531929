<template>
    <div
        ref="chartdiv"
        style="height: 700px"
        class="p-3"
    />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { hslToHex } from '../utils/form-utils.js';

am4core.useTheme(am4themes_animated);

export default {
    props: {
        chartData: {
            default: () => {},
            type: Object,
            required: true
        },
        worldData: {
            default: () => [],
            type: Array,
            required: true
        }
    },

    data() {
        return {
            chart: undefined,

            zoomOut: undefined
        };
    },

    watch: {
        worldData() {
            this.updateWorldMapData();
        }
    },

    beforeUnmount() {
        if (this.chart)
            this.chart.dispose();
    },

    mounted() {
        const OPACITY = 0.5;
        const SATURATION = 0.9;

        const chart = am4core.create(this.$refs.chartdiv, am4maps.MapChart);

        chart.geodata = am4geodata_worldLow;
        chart.projection = new am4maps.projections.Mercator();
        chart.background.fill = am4core.color('#2a3042');
        chart.background.fillOpacity = 1;

        // zoomout on background click
        chart.chartContainer.background.events.on("hit", () => {
            zoomOut();
        });

        let morphedPolygon;

        // map polygon series (countries)
        const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        polygonSeries.include = [];

        // country area look and behavior
        const polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.strokeOpacity = 1;
        polygonTemplate.stroke = am4core.color("#ffffff");
        polygonTemplate.fillOpacity = OPACITY;
        polygonTemplate.tooltipText = "{name}: {value} kg";

        polygonTemplate.adapter.add("fill", (fill, target) =>
            am4core.color(selectColor(target.dataItem.value))
        );

        // desaturate filter for countries
        const desaturateFilter = new am4core.DesaturateFilter();
        desaturateFilter.saturation = SATURATION;
        polygonTemplate.filters.push(desaturateFilter);

        // set fillOpacity to 1 when hovered
        const hoverState = polygonTemplate.states.create("hover");
        hoverState.properties.fillOpacity = 0.9;

        // what to do when country is clicked
        polygonTemplate.events.on("hit", (event) => {
            event.target.zIndex = 1000000;
            selectPolygon(event.target);
        });

        // Pie chart
        const pieChart = chart.seriesContainer.createChild(am4charts.PieChart);
        // Set width/heigh of a pie chart for easier positioning only
        pieChart.width = 100;
        pieChart.height = 100;
        pieChart.hidden = true; // can't use visible = false!

        // because defauls are 50, and it's not good with small countries
        pieChart.chartContainer.minHeight = 1;
        pieChart.chartContainer.minWidth = 1;

        const pieSeries = pieChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.data = [ //Initialize, 3 BECUASE WE HAVE CURRENTLY 3 SPECIES
            {value: 0, category: ''},
            {value: 0, category: ''},
            {value: 0, category: ''}
        ];

        const dropShadowFilter = new am4core.DropShadowFilter();
        dropShadowFilter.blur = 4;
        pieSeries.filters.push(dropShadowFilter);

        const sliceTemplate = pieSeries.slices.template;
        sliceTemplate.fillOpacity = 1;
        sliceTemplate.strokeOpacity = 0;

        const activeState = sliceTemplate.states.getKey("active");
        activeState.properties.shiftRadius = 0; // no need to pull on click, as country circle under the pie won't make it good

        const sliceHoverState = sliceTemplate.states.getKey("hover");
        sliceHoverState.properties.shiftRadius = 0; // no need to pull on click, as country circle under the pie won't make it good

        // we don't need default pie chart animation, so change defaults
        const hiddenState = pieSeries.hiddenState;
        hiddenState.properties.startAngle = pieSeries.startAngle;
        hiddenState.properties.endAngle = pieSeries.endAngle;
        hiddenState.properties.opacity = 0;
        hiddenState.properties.visible = false;

        // series labels
        const labelTemplate = pieSeries.labels.template;
        labelTemplate.nonScaling = true;
        labelTemplate.fill = am4core.color("#FFFFFF");
        labelTemplate.fontSize = 10;
        labelTemplate.background = new am4core.RoundedRectangle();
        labelTemplate.background.fillOpacity = 0.9;
        labelTemplate.padding(4, 9, 4, 9);
        labelTemplate.background.fill = am4core.color("#7678a0");

        // we need pie series to hide faster to avoid strange pause after country is clicked
        pieSeries.hiddenState.transitionDuration = 200;

        // country label
        const countryLabel = chart.chartContainer.createChild(am4core.Label);
        countryLabel.fontSize = 40;
        countryLabel.hiddenState.properties.dy = 1000;
        countryLabel.defaultState.properties.dy = 0;
        countryLabel.valign = "middle";
        countryLabel.align = "right";
        countryLabel.paddingRight = 50;
        countryLabel.hide(0);

        const noDataLabel = chart.chartContainer.createChild(am4core.Label);
        noDataLabel.fontSize = 50;
        noDataLabel.hiddenState.properties.dy = 1000;
        noDataLabel.defaultState.properties.dy = 0;
        noDataLabel.valign = "middle";
        noDataLabel.align = "center";
        noDataLabel.fill = am4core.color('#3b909f');
        noDataLabel.text = "No data to show.";

        //global vars
        this.chart = chart;
        const props = this.$props;

        function selectColor(value) {
            const map = props.worldData.map(item => item.value);
            const max = Math.max.apply(null, map);
            const min = Math.min.apply(null, map);
            const norm = (value - min) / (max - min);
            const h = 183;
            const s = norm * 100;
            const l = 50;
            return hslToHex(h, s, l);
        }

        // select polygon
        function selectPolygon(polygon) {
            if (morphedPolygon != polygon) {
                const animation = pieSeries.hide();
                if (animation) {
                    animation.events.on("animationended", () => {
                        morphToCircle(polygon);
                    });
                } else {
                    morphToCircle(polygon);
                }
            }
        }

        // fade out all countries except selected
        function fadeOut(exceptPolygon) {
            for (let i = 0; i < polygonSeries.mapPolygons.length; i++) {
                const polygon = polygonSeries.mapPolygons.getIndex(i);
                if (polygon != exceptPolygon) {
                    polygon.defaultState.properties.fillOpacity = 0.5;
                    polygon.animate(
                        [
                            { property: "fillOpacity", to: OPACITY },
                            { property: "strokeOpacity", to: 1 },
                        ],
                        polygon.polygon.morpher.morphDuration
                    );
                }
            }
        }

        function zoomOut() {
            if (morphedPolygon) {
                pieSeries.hide();
                morphBack();
                fadeOut();
                countryLabel.hide();
                morphedPolygon = undefined;
            }
        }
        this.zoomOut = zoomOut;

        function morphBack() {
            if (morphedPolygon) {
                morphedPolygon.polygon.morpher.morphBack();
                const dsf = morphedPolygon.filters.getIndex(0);
                dsf.animate(
                    { property: "saturation", to: SATURATION },
                    morphedPolygon.polygon.morpher.morphDuration
                );
            }
        }

        function morphToCircle(polygon) {
            const animationDuration = polygon.polygon.morpher.morphDuration;
            // if there is a country already morphed to circle, morph it back
            morphBack();
            // morph polygon to circle
            polygon.toFront();
            polygon.polygon.morpher.morphToSingle = true;
            const morphAnimation = polygon.polygon.morpher.morphToCircle();

            polygon.strokeOpacity = 0; // hide stroke for lines not to cross countries

            polygon.defaultState.properties.fillOpacity = 1;
            polygon.animate(
                { property: "fillOpacity", to: 1 },
                animationDuration
            );

            // animate desaturate filter
            const filter = polygon.filters.getIndex(0);
            filter.animate(
                { property: "saturation", to: 1 },
                animationDuration
            );

            // save currently morphed polygon
            morphedPolygon = polygon;

            // fade out all other
            fadeOut(polygon);

            // hide country label
            countryLabel.hide();

            if (morphAnimation) {
                morphAnimation.events.on("animationended", () => {
                    zoomToCountry(polygon);
                });
            } else {
                zoomToCountry(polygon);
            }
        }

        function zoomToCountry(polygon) {
            const zoomAnimation = chart.zoomToMapObject(polygon, 2.2, true);
            if (zoomAnimation) {
                zoomAnimation.events.on("animationended", () => {
                    showPieChart(polygon);
                });
            } else {
                showPieChart(polygon);
            }
        }

        function showPieChart(polygon) {
            polygon.polygon.measure();
            const radius =
                ((polygon.polygon.measuredWidth / 2) * polygon.globalScale) /
                chart.seriesContainer.scale;
            pieChart.width = radius * 2;
            pieChart.height = radius * 2;
            pieChart.radius = radius;

            let centerPoint = am4core.utils.spritePointToSvg(
                polygon.polygon.centerPoint,
                polygon.polygon
            );
            centerPoint = am4core.utils.svgPointToSprite(
                centerPoint,
                chart.seriesContainer
            );

            pieChart.x = centerPoint.x - radius;
            pieChart.y = centerPoint.y - radius;

            const fill = polygon.fill;
            const desaturated = fill.saturate(0.3);

            //Set data of pie chart
            const id = polygon.dataItem.dataContext.id;
            for (let i = 0; i < pieSeries.dataItems.length; i++) {
                const dataItem = pieSeries.dataItems.getIndex(i);
                if (i < props.chartData[id].length) {
                    dataItem.value = props.chartData[id][i].value;
                    dataItem.category = props.chartData[id][i].specie;
                    dataItem.slice.fill = am4core.color(
                            am4core.colors.interpolate(
                                    fill.rgb,
                            am4core.color("#ffffff").rgb,
                            0.35 * i
                        )
                    );
                    dataItem.label.background.fill = desaturated;
                    dataItem.tick.stroke = fill;
                    dataItem.show();
                } else {
                    dataItem.hide();
                }
            }

            pieSeries.show();
            pieChart.show();

            countryLabel.text = "{name}: {value} kg";
            countryLabel.dataItem = polygon.dataItem;
            countryLabel.fill = desaturated;
            countryLabel.show();
        }
    },

    methods: {
        updateWorldMapData() {
            this.zoomOut();
            
            const polygonSeries = this.chart.series.getIndex(0);
            const label = this.chart.chartContainer.children.values[3];
            polygonSeries.data = this.worldData;
            polygonSeries.include = this.worldData.map(item => item.id);

            if (this.worldData.length == 0) {
                label.show();
            } else {
                label.hide();
            }
        }
    }
}
</script>