<template>
    <select
        v-model="localValue"
        :class="`${error != '' ? 'is-invalid' : ''} inline-form-control`"
    >
        <option
            v-for="(obj, idx) in dataRange"
            :key="idx"
            :value="obj.value"
        >
            {{ obj.name }}
        </option>
    </select>
    <div
        v-if="error != ''"
        class="invalid-feedback"
    >
        {{ error }}
    </div>
</template>

<script>
export default {
    props: {
        range: {
            type: Array(Object),
            default: () => [],
            required: true
        },

        modelValue: {
            type: [String, Number],
            default: () => ''
        },

        error: {
            type: String,
            default: () => ''
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            updateFunc: this.updateMethod,

            dataRange: this.range,

            localValue: this.modelValue
        }
    },

    watch: {
        localValue(newVal) {
            this.$emit('update:modelValue', newVal);
        },

        modelValue(newVal) {
            this.localValue = newVal;
        }   
    }
}
</script>