<template>
    <div
        v-if="buffer.error != ''"
        class="alert alert-danger"
    >
        {{ buffer.error }}
    </div>

    <div v-if="!buffer.error">
        <year-selector
            v-model="buffer.selectedYear"
            class="responsive-mx-4"
            @year-updated="updateYear()"
        />

        <!-- Company name -->
        <div>
            <p class="h1 text-center my-4">
                {{ buffer.company.companyName }}
            </p>
        </div>

        <!-- Filled-in KPIs check -->
        <div class="responsive-mx-4">
            <h4>{{ __('DASHBOARD.FILLED_FORMS.TITLE') }}</h4>
            <div class="position-relative">
                <loading-layer :is-loading="buffer.filledFormsLoading" />
                <filled-forms
                    :forms-to-show="buffer.formsToShow"
                    :filled-types="displayableData.filledForms"
                />
            </div>
        </div>


        <div class="top-panel">
            <!-- ECONOMIC PIE CHART -->
            <div
                v-if="displayableData.pieChart.length != 0"
                class="card responsive-mx-4 d-block my-4 p-4 top-panel-first_item"
            >
                <pie-chart
                    :chart-data="displayableData.pieChart"
                    :unknown-tag="__('LABELS.UNKNOWN_COSTS')"
                />
            </div>

            <!-- FIRST LINE KPIS -->
            <div
                class="d-flex flex-wrap justify-content-center top-panel-second_item"
            >
                <div
                    v-if="displayableData.firstLine.length == 0"
                    style="width: 100%"
                    class="card responsive-mx-4"
                >
                    <loading-layer :is-loading="buffer.isLoading" />
                    <p class="h4 text-center my-4 text-secondary">
                        {{ __('COMMON.NO_KPIS') }}
                    </p>
                </div>
                <numeric-metric
                    v-for="spec in displayableData.firstLine.filter(spec => displayableData.pieChart.filter(pie => pie.title == spec.title).length == 0)"
                    :key="spec.title"
                    :value="spec.value"
                    :title="spec.title"
                    :unit="spec.unit"
                />
            </div>
        </div>

        <!-- First line KPIs -->
        <!-- <div
            style=""
            class="flex-wrap d-flex justify-content-center"
        >
            <div
                v-if="displayableData.firstLine.length == 0"
                style="width: 100%"
                class="card responsive-mx-4"
            >
                <loading-layer :is-loading="buffer.isLoading" />
                <p class="h4 text-center my-4 text-secondary">
                    {{ __('COMMON.NO_KPIS') }}
                </p>
            </div>
            <numeric-metric
                v-for="spec in displayableData.firstLine"
                :key="spec.title"
                :value="spec.value"
                :title="spec.title"
                :unit="spec.unit"
            />
        </div> -->


        <p class="h5 text-center mt-5">
            <dropdown-list
                v-model="buffer.selectedKpiWorldMap"
                :range="buffer.kpisInWorldMap"
            /> {{ __('DASHBOARD.CHART_MAP') }}
        </p>
        <div class="card responsive-mx-4">
            <loading-layer :is-loading="buffer.worldMapLoading" />
            <map-pie-chart
                :chart-data="displayableData.chartData"
                :world-data="displayableData.worldData"
            />
        </div>

        <p class="h5 text-center mt-5">
            {{ __('DASHBOARD.NUM_FACILITIES') }}
        </p>
        <div class="card responsive-mx-4">
            <loading-layer :is-loading="buffer.treemapDataLoading" />
            <treemap-chart :chart-data="displayableData.treemapData" />
        </div>
    </div>
</template>

<script>
import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';

import {
    getCountryIso,
    getCountryName,
    getKpi,
    getKpiOperations,
    getSpecieName } from '../../db/db-utils.js';

import YearSelector from '../../components/year_selector.vue';
import DropdownList from '../../components/dropdown_list.vue';
import NumericMetric from '../../components/numeric_metric.vue';
import MapPieChart from '../../components/map-pie_chart.vue';
import TreemapChart from '../../components/treemap_chart.vue';
import LoadingLayer from '../../components/loading_layer.vue';
import FilledForms from '../../components/filled-forms.vue';
import PieChart from '../../components/pie-chart.vue';

import Constants from '../../Constants.js';
import Operation from '../../utils/operation.js';

export default {
    components: {
        YearSelector,
        MapPieChart,
        TreemapChart,
        DropdownList,
        LoadingLayer,
        NumericMetric,
        FilledForms,
        PieChart
    },

    inject: ['COMPANY'],

    data() {
        return {
            buffer: {
                company: this.COMPANY.value,

                selectedYear: Constants.MIN_KPIDATE_VALUE,

                formsToShow: [0, 1, 2, 3, 4, 5],

                kpisInDashboard: [18, 14, 15, 16, 17, 20, 22, 12],

                kpisInFirstLine: [18, 14, 20, 12],

                kpisInPieChart: [14, 15, 16, 17, 22],

                kpisInWorldMap: getKpi(12, 20).map(k => {
                    k.value = k.kpiId;
                    return k;
                }),
                selectedKpiWorldMap: 12,

                filledFormsLoading: false,
                worldMapLoading: false,
                treemapDataLoading: false,
                isLoading: true,
                error: ''
            },

            displayableData: {
                filledForms: [],

                firstLine: [],
                pieChart: [],

                worldData: [],
                chartData: {},

                treemapData: {}
            }
        }
    },

    watch: {
        'buffer.selectedKpiWorldMap'(newVal) {
            this.buffer.worldMapLoading = true;
            this.worldDataCoroutine(newVal)
                .finally(() => { this.buffer.worldMapLoading = false; });
        }
    },

    created() {
        this.updateYear();
    },

    methods: {
        resetData() {
            this.displayableData.firstLine.length = 0;
        },

        updateYear() {
            this.buffer.isLoading = true;
            this.buffer.worldMapLoading = true;
            this.buffer.treemapDataLoading = true;
            this.buffer.filledFormsLoading = true;
            this.resetData();

            const promises = [];
            promises.push(API.analytics.filter({
                    year: this.buffer.selectedYear,
                    kpiId: this.buffer.kpisInDashboard
                })
                .then(response => {
                    if (response.ok) {
                        this.prepareFirstLineData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(console.log)
                .catch(() => this.buffer.error = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false)
            );

            promises.push(API.analytics.filledForms(this.buffer.selectedYear)
                .then(response => {
                    if (response.ok) {
                        this.displayableData.filledForms = response.data;
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.')
                .finally(() => this.buffer.filledFormsLoading = false)
            );

            promises.push(this.worldDataCoroutine(this.buffer.selectedKpiWorldMap));

            promises.push(API.analytics.treemapData(this.buffer.selectedYear)
                .then(response => {
                    if (response.ok) {
                        this.prepareTreemapData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.')
                .finally(() => this.buffer.treemapDataLoading = false)
            );
        },

        async worldDataCoroutine(kpiId) {
            return API.analytics.worldData(this.buffer.selectedYear, kpiId)
                .then(response => {
                    if (response.ok) {
                        this.prepareWorldData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.')
                .finally(() => this.buffer.worldMapLoading = false);
        },

        prepareFirstLineData(rawData) {
            const dashboardKpis = {};
            rawData.forEach(kpi => {
                if (this.buffer.kpisInDashboard.indexOf(kpi.kpiId) == -1)
                    return;

                if (dashboardKpis[kpi.facility] == undefined) {
                    dashboardKpis[kpi.facility] = {};
                    dashboardKpis[kpi.facility][kpi.activityId] = {};
                    dashboardKpis[kpi.facility][kpi.activityId][kpi.kpiId] = [kpi.value];
                } else if (dashboardKpis[kpi.facility][kpi.activityId] == undefined) {
                    dashboardKpis[kpi.facility][kpi.activityId] = {};
                    dashboardKpis[kpi.facility][kpi.activityId][kpi.kpiId] = [kpi.value];
                } else if (dashboardKpis[kpi.facility][kpi.activityId][kpi.kpiId] == undefined) {
                    dashboardKpis[kpi.facility][kpi.activityId][kpi.kpiId] = [kpi.value];
                } else {
                    dashboardKpis[kpi.facility][kpi.activityId][kpi.kpiId].push(kpi.value);
                }
            });
            
            const operationsObj = {};
            const activityCalculation = {};
            Object.entries(dashboardKpis).forEach(([facility, activityObj]) => {
                activityObj; facility;

                const specieCalculation = {};

                Object.entries(activityObj).forEach(([activityId, kpiObj]) => {
                    activityId; kpiObj;

                    //Specie calculation
                    Object.entries(kpiObj).forEach(([kpiId, values]) => {
                        if (operationsObj[kpiId] == undefined) {
                            operationsObj[kpiId] = {};
                            operationsObj[kpiId] = getKpiOperations(kpiId);
                        }

                        const oper = new Operation(operationsObj[kpiId].specieOperation);
                        const result = oper.compute(values);

                        if (specieCalculation[kpiId] == undefined) {
                            specieCalculation[kpiId] = [result];
                        } else {
                            specieCalculation[kpiId].push(result);
                        }
                    });
                });

                //Activity calculation
                Object.entries(specieCalculation).forEach(([kpiId, values]) => {
                        const oper = new Operation(operationsObj[kpiId].activityOperation);
                        const result = oper.compute(values);

                        if (activityCalculation[kpiId] == undefined) {
                            activityCalculation[kpiId] = [result];
                        } else {
                            activityCalculation[kpiId].push(result);
                        }
                });
            });


            const pieChartData = [];
            Object.entries(activityCalculation).forEach(([kpiId, values]) => {
                const oper = new Operation(operationsObj[kpiId].facilityOperation, { decimals: 2 });
                const kpiSpec = getKpi(kpiId);
                const value = oper.compute(values);

                if (this.buffer.kpisInFirstLine.indexOf(Number(kpiId)) != -1) {
                    this.displayableData.firstLine.push({
                        value,
                        title: kpiSpec.name,
                        unit: kpiSpec.unit
                    });
                }

                if (this.buffer.kpisInPieChart.indexOf(Number(kpiId)) != -1) {
                    pieChartData.push({
                        value,
                        title: kpiSpec.name,
                    });
                }
            });
            this.displayableData.pieChart = pieChartData;
        },

        prepareWorldData(rawData) {
            const chartData = {};
            rawData.forEach((row) => {
                const key = getCountryIso(row.countryId);
                if (chartData[key] == undefined)
                    chartData[key] = [];

                chartData[key].push({
                    specie: getSpecieName(row.specieId),
                    value: row.value
                });
            });
            
            //Operation we need to make with the kpis
            const oper = new Operation(getKpiOperations(this.buffer.selectedKpiWorldMap).specieOperation);
            const worldData = [];
            Object.keys(chartData).forEach(key => {
                const obj = {
                    id: key,
                    name: getCountryName(key),
                    value: oper.compute(chartData[key], 'value')
                };
                worldData.push(obj);
            });
            
            this.displayableData.chartData = chartData;
            this.displayableData.worldData = worldData;
        },

        prepareTreemapData(rawData) {
            rawData = rawData.map(c => {
                c.name = getCountryName(c.name);
                c.value = c.children.map(s => s.value).reduce((a, b) => a + b);
                c.children = c.children.map(s => {
                    s.name = getSpecieName(s.name);
                    return s;
                })
                return c;
            });

            this.displayableData.treemapData = rawData;
        },

        __ //translator
    }
}
</script>